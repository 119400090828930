<template>
    <div class="layout-device">
        <Spin fix v-if="deviceInfoLoading"></Spin>
        <div class="device-menu-edit">
            <div class="menu-top">
                <div class="menu-tab">
                    <Breadcrumb>
                        <BreadcrumbItem to="/device/list">设备知识库 </BreadcrumbItem>
                        <BreadcrumbItem>设备详情</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div class="menu-info" :class="structuralId === ''?'menu-info-active':''" @click="onDevice">
                    <span class="info-image" :style="'background-image:url('+deviceInfo.equipmentPictures+')'"></span>
                    <div class="info-name-box"><p class="info-name">{{ deviceInfo.equipmentName }}</p></div>
                </div>
                <div class="menu-list-top">
                    <h1 class="title">部件列表（{{ deviceInfo.partsSize }}）</h1>
                    <a href="javascript:" @click="onEditStructural('')"><Icon type="ios-add-circle-outline" /></a>
                </div>
            </div>
            <div class="menu-list">
                <div class="list-item">
                    <div class="item" :key="'item'+key" v-for="(item,key) in deviceStructuralArray">
                        <div class="item-thead" :class="item.id === structuralId?'item-active':''">
                            <a href="javascript:" class="href">
                                <Icon v-if="item.childNode.length !== 0" :type="item.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon"  @click="item.visible = !item.visible"/>
                                <p :style="item.childNode.length === 0?'margin-left:25px':''" @click="onSelectPart(item)">{{ item.partsName }}</p>
                            </a>
                            <div class="child-system">
                                <Dropdown placement="bottom">
                                    <a href="javascript:">
                                        <Icon :custom="item.id === deviceSelectPart.id?'more-blue':'more'"/>
                                    </a>
                                    <DropdownMenu slot="list">
                                        <DropdownItem>
                                            <a href="javascript:" @click="onEditStructural(item,item.id,deviceStructuralArray,'')">编辑</a>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <a href="javascript:" @click="onDelStructural(item.id)">删除</a>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <a href="javascript:" class="href" @click="onEditStructural('',item.id)"><Icon type="ios-add-circle-outline" class="icon"/></a>
                            </div>
                        </div>
                        <div class="item-body" v-if="item.visible">
                            <div class="item-child" :key="'child2'+key" v-for="(children,key) in item.childNode">
                                <div class="child-thead" :class="children.id === structuralId?'children-active':''" :style="children.childNode.length === 0?'padding-left:20px':''">
                                    <a href="javascript:" class="href">
                                        <Icon v-if="children.childNode.length !== 0"  @click="children.visible = !children.visible" :type="children.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon" />
                                        <p :style="children.childNode.length === 0?'margin-left:20px':''" @click="onSelectPart(children)">{{ children.partsName }}</p>
                                    </a>
                                    <div class="child-system">
                                        <Dropdown placement="bottom">
                                            <a href="javascript:">
                                                <Icon :custom="children.id === deviceSelectPart.id?'more-blue':'more'"/>
                                            </a>
                                            <DropdownMenu slot="list">
                                                <DropdownItem>
                                                    <a href="javascript:" @click="onUpgradeDevice(children.id)">升级</a>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <a href="javascript:" @click="onEditStructural(children,children.id,deviceStructuralArray,item.id)">编辑</a>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <a href="javascript:" @click="onDelStructural(children.id)">删除</a>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                        <a href="javascript:" class="href" @click="onEditStructural('',children.id)"><Icon type="ios-add-circle-outline" class="icon"/></a>
                                    </div>
                                </div>
                                <div class="child-body" v-if="children.visible">
                                    <ul>
                                        <li :key="'child3'+key" v-for="(child,key) in children.childNode" :class="child.id === structuralId?'child-active':''" >
                                            <a href="javascript:" class="href" @click="onSelectPart(child)">
                                                <Icon type="ios-copy-outline" class="icon"/>
                                                {{ child.partsName }}
                                            </a>
                                            <div class="child-system">
                                                <Dropdown placement="bottom">
                                                    <a href="javascript:">
                                                        <Icon :custom="child.id === deviceSelectPart.id?'more-blue':'more'"/>
                                                    </a>
                                                    <DropdownMenu slot="list">
                                                        <DropdownItem>
                                                            <a href="javascript:" @click="onUpgradeDevice(child.id)">升级</a>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <a href="javascript:" @click="onEditStructural(child,child.id,item.childNode,children.id)">编辑</a>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <a href="javascript:" @click="onDelStructural(child.id)">删除</a>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-device-edit" :class="deviceTabsNumber === 4?'device-bg':''">
            <div class="layout-device-tab">
                <div class="tab-li">
                    <div class="tabs-tab" :key="key" v-for="(tabs,key) in deviceTabs" :class="deviceTabsNumber === tabs.label?'active':''">
                        <a href="javascript:" @click="onTabsSwitch(tabs.label)">
                            <p v-if="tabs.label === 6">{{ tabs.value }}</p>
                            <p v-if="tabs.label === 7">{{ tabs.value }}</p>
                            <p v-if="tabs.label === 9">{{ tabs.value }}</p>
                            <p v-if="tabs.label === 1">{{ `${tabs.value}  （${structuralId===''? deviceInfo.instructionUseFileSize : structuralInfo.instructionUseFileSize}）`}}</p>
                            <p v-if="tabs.label === 2">{{ `${tabs.value}  （${structuralId===''? deviceInfo.mechanicalAtlasFileSize : structuralInfo.mechanicalAtlasFileSize}）`}}</p>
                            <p v-if="tabs.label === 3">{{ `${tabs.value}  （${structuralId===''? deviceInfo.electricalAtlasFileSize : structuralInfo.electricalAtlasFileSize}）`}}</p>
                            <p v-if="tabs.label === 8">{{ tabs.value }}</p>
                            <p v-if="tabs.label === 5">{{ `${tabs.value}  （${structuralId===''? deviceInfo.faultAnalysisSize : structuralInfo.faultAnalysisSize}）`}}</p>
                            <p v-if="tabs.label === 0">{{ `${tabs.value}  （${structuralId===''? deviceInfo.otherUseFileSize : structuralInfo.otherUseFileSize}）`}}</p>
                        </a>
                    </div>
                </div>
                <div class="layout-device-content">
                    <div class="layout-device-info-li" v-if="deviceTabsNumber === 6 && structuralId === ''">
                        <div class="box">
                            <ul>
                                <li class="item">
                                    <span class="label required">分类：</span>
                                    <Cascader style="width: 300px"  :data="classifyMenuArray" v-model="newlyParams.deviceType"></Cascader>
                                </li>
                                <li class="item">
                                    <span class="label required">设备名称：</span>
                                    <Input v-model="newlyParams.deviceName" maxlength="25" show-word-limit placeholder="请输入"  style="width: 300px" />
                                </li>
                                <li class="item">
                                    <span class="label required">设备型号：</span>
                                    <Select v-model="newlyParams.deviceModel" clearable  style="width:300px">
                                        <Option v-for="(deviceModel,key) in deviceModelArray" :value="deviceModel.id" :key="key">{{ deviceModel.name }}</Option>
                                    </Select>
                                    <a href="javascript:" class="add" @click="deviceModelVisible = true"><Icon custom="icon-manage" style="margin-right: 4px"/>管理设备型号</a>
                                </li>
                                <li class="item">
                                    <span class="label required">生产厂家：</span>
                                    <Select v-model="newlyParams.manufacturer" clearable style="width:300px">
                                        <Option v-for="(manufacturer,key) in manufacturerArray" :value="manufacturer.id" :key="key">{{ manufacturer.name }}</Option>
                                    </Select>
                                    <a href="javascript:" class="add" @click="manufacturerVisible = true"><Icon custom="icon-manage" style="margin-right: 4px"/>管理生产厂家</a>
                                </li>
                                <li class="item">
                                    <span class="label">设备图片：</span>
                                    <div class="device-upload">
                                        <a href="javascript:" class="btn" v-if="!speedSingleVisible">
                                            <Icon type="ios-add" class="icon"/>
                                            上传图片
                                        </a>
                                        <div class="progress" v-if="speedSingleVisible">
                                            <Progress :percent="complete" />
                                        </div>
                                        <input type="file" ref="pathClear" accept="image/*" v-if="!speedSingleVisible" class="file" @change="onFileChange($event)">
                                        <div class="file-image" v-if="newlyParams.filesUrl!==''">
                                            <img :src="newlyParams.filesUrl" class="image">
                                            <div class="handle">
                                                <div class="box">
                                                    <a href="javascript:" @click="onImageAmplify(deviceInfoObj.name,newlyParams.filesUrl,newlyParams.files)"><Icon custom="look-big" :size="25"/></a>
                                                    <a href="javascript:" @click="onClearImage(6)"><Icon type="ios-trash-outline" :size="25"/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="item layout-device-btn">
                                    <span class="label"></span>
    <!--                                    <Button @click="onCancel">取消</Button>-->
                                    <Button type="primary" :disabled="speedSingleVisible" :loading="submitLoading" @click="onAddRecordCheck">保存</Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 6 && structuralId !== ''">
                        <div class="tab-part-content">
                            <!--s: 右侧-->
                            <div class="part-right">
                                <div v-if="JSON.stringify(deviceSelectPart) === '{}' || deviceSelectPart.fileUrl === ''" class="part-right-no-image"></div>
                                <div v-if="JSON.stringify(deviceSelectPart) !== '{}' && deviceSelectPart.fileUrl !== ''" class="part-right-content">
                                    <span v-if="onFileFormatCheck(deviceSelectPart.fileUrl)" :style="'background-image:url('+deviceSelectPart.file+')'" class="image"></span>
                                    <video v-if="!onFileFormatCheck(deviceSelectPart.fileUrl) && deviceSelectPart.file" :src="deviceSelectPart.file" width="100%" height="100%" autoplay controls class="file-video"></video>
                                </div>
                                <div class="part-top-bar" v-if="JSON.stringify(deviceSelectPart) !== '{}'">
                                    <div class="bar-body">
                                        <Poptip placement="top" theme="light">
                                            <a href="javascript:" class="info"></a>
                                            <div slot="content"  class="bar-content">
                                                <span class="title"><font></font> 部件型号：</span>
                                                <div class="text">{{ deviceSelectPart.partsModel === ''?'未添加部件型号':deviceSelectPart.partsModel }}</div>
                                                <span class="title"><font></font> 部件说明：</span>
                                                <div class="text">{{ deviceSelectPart.partsBlurb === ''?'未添加简介':deviceSelectPart.partsBlurb }}</div>
                                            </div>
                                        </Poptip>
                                        <a href="javascript:" class="look" @click="onImageAmplify(deviceSelectPart.partsName,deviceSelectPart.file,deviceSelectPart.fileUrl)"></a>
                                    </div>
                                </div>
                            </div>
                            <!--e: 右侧-->
                        </div>
                    </div>
                    <div class="tab-box" v-if="deviceTabsNumber === 7">
                        <div class="device-info-con">
                            <div class="device-info-content">
                                <Input v-if="deviceInfoStatus" type="textarea" v-model="deviceInfoContent"></Input>
                                <div class="content" v-if="!deviceInfoStatus">{{ deviceInfoContent.length === 0?'暂无简介':deviceInfoContent }}</div>
                            </div>
                            <div class="device-info-btn">
                                <span class="number">已输入<font>{{ deviceInfoContent.length }}</font>字</span>
                                <div class="btn">
                                    <Button v-if="deviceInfoStatus" @click="deviceInfoStatus = false;deviceInfoContent = deviceInfoObj.introContent">取消</Button>
                                    <Button type="primary" v-if="deviceInfoStatus" :loading="deviceInfoContentLoading" @click="onAddDeviceContentCheck">保存</Button>
                                    <Button type="primary" v-if="!deviceInfoStatus" @click="deviceInfoStatus = true" icon="md-create">编辑</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-box" v-if="deviceTabsNumber === 9">
                        <div class="device-info-parameter" :style="'height:'+parameterHeight+'px'">
                            <div class="parameter-title">
                                <a href="javascript:" v-if="!parameterStatus" @click="parameterStatus = true"><Icon type="ios-create" size="20"/>编辑</a>
                                <Button type="primary" v-if="parameterStatus" :loading="parameterLoading" @click="onParameterSave">保存</Button>
                            </div>
                            <div class="parameter-list">
                                <div class="box" :key="key" v-for="(deviceInfo,key) in deviceInfoArray">
                                    <div class="list-title">
                                        <div class="list-name" :class="parameterStatus?'list-name-cursor':''" @click="onParamTitle(key)">
                                            <Icon type="ios-square" size="18" v-if="!parameterStatus"/>
                                            <Icon type="md-remove-circle" size="18" v-if="parameterStatus"/>
                                            <p class="name" v-if="!deviceInfo.visible">{{ deviceInfo.titleName }}</p>
                                            <Icon type="md-create" class="create" v-if="parameterStatus  && !deviceInfo.visible" size="18" style="margin-left: 10px; display: none"/>
                                            <Input v-model="deviceInfo.titleName" :ref="'paramTitle'+key" v-show="deviceInfo.visible" maxlength="20" show-word-limit placeholder="请输入标题" style="width: 90%" />
                                        </div>
                                        <div class="list-operate" v-if="parameterStatus">
                                            <Button icon="md-add" type="text" ghost @click="onParameterAdd(key)">添加一行</Button>
                                            <Dropdown placement="bottom" @click.native.stop v-if="key !==0">
                                              <a href="javascript:">
                                                <Icon type="ios-more"  size="20" />
                                              </a>
                                              <DropdownMenu slot="list">
                                                <DropdownItem v-if="key !==0" @click.native="onDeleteParamItem(key)">
                                                  <a href="javascript:">删除</a>
                                                </DropdownItem>
                                                <DropdownItem v-if="key !==0" @click.native="onParamToppingItem(key)">
                                                  <a href="javascript:">置顶</a>
                                                </DropdownItem>
                                                <DropdownItem v-if="key !==0" @click.stop @click.native="onParamUpItem(key)">
                                                  <a href="javascript:">上移</a>
                                                </DropdownItem>
                                                <DropdownItem v-if="key+1 !== deviceInfoArray.length" @click.native="onParamDownItem(key)">
                                                  <a href="javascript:">下移</a>
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div class="list-table" :class="parameterStatus?'list-table-cursor':''">
                                        <Table border :columns="parameterColumns" :data="deviceInfo.paramDetail">
                                            <template slot-scope="{ row,index }" slot="名称">
                                                <div class="list-input" @click="onParameterName(key,index)">
                                                    {{ !row.nameVisible?row.paramName:'' }}
                                                    <Input ref="refInput" v-model="deviceInfo.paramDetail[index].paramName" v-if="row.nameVisible" maxlength="20" @on-blur="row.nameVisible = false" show-word-limit placeholder="请输入名称" style="width: 90%" />
                                                    <Icon type="ios-create" size="18"/>
                                                </div>
                                            </template>
                                            <template slot-scope="{ row,index }" slot="参数">
                                                <div class="list-input" @click="onParameterParam(key,index)">
                                                    {{ !row.paramVisible?row.paramValue:'' }}
                                                    <Input ref="refInput" v-model="deviceInfo.paramDetail[index].paramValue" autofocus v-if="row.paramVisible" maxlength="50" @on-blur="row.paramVisible = false" show-word-limit placeholder="请输入参数" style="width: 80%" />
                                                    <Dropdown placement="bottom" @click.native.stop v-if="index !==0">
                                                        <a href="javascript:">
                                                            <Icon type="ios-more"  size="20" />
                                                        </a>
                                                        <DropdownMenu slot="list">
                                                            <DropdownItem v-if="index !==0" @click.native="onDeleteParam(key,index)">
                                                              <a href="javascript:">删除</a>
                                                            </DropdownItem>
                                                            <DropdownItem v-if="index !==0" @click.native="onParamTopping(key,index)">
                                                                <a href="javascript:">置顶</a>
                                                            </DropdownItem>
                                                            <DropdownItem v-if="index !==0" @click.stop @click.native="onParamUp(key,index)">
                                                                <a href="javascript:">上移</a>
                                                            </DropdownItem>
                                                            <DropdownItem v-if="index+1 !== deviceInfo.paramDetail.length" @click.native="onParamDown(key,index)">
                                                                <a href="javascript:">下移</a>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                    <Icon type="ios-create" size="18"/>
                                                </div>
                                            </template>
                                        </Table>
                                    </div>
                                </div>
                                <div class="parameter-no-data" v-if="deviceInfoArray.length === 0">暂无数据</div>
                                <div class="parameter-btn">
                                    <Button icon="ios-add-circle-outline" v-if="parameterStatus" @click="onAddParameter" type="text" ghost >新增</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-box" v-if="deviceTabsNumber === 8">
                        <div class="principle-box" :style="'height:'+parameterHeight+'px'">
                            <div class="device-info-principle">
                                <div class="principle-border" v-if="JSON.stringify(deviceInfoObj) === '{}' || (deviceInfoObj.detailDTOList && deviceInfoObj.detailDTOList.length === 0)">
                                    <div class="icon"></div>
                                    <div class="text">
                                        建议上传横屏素材<br/>JPG、JPEG、MP4、FLV格式；文件大小不超过100MB
                                    </div>
                                    <a href="javascript:" class="add" @click="choiceVideo(0)">上传视频</a>
                                </div>
                                <div class="principle-video" v-if="deviceInfoObj.detailDTOList && deviceInfoObj.detailDTOList.length!==0">
                                    <div class="title">资料文件<font>（图片支持JPG、JPEG、PNG格式；视频支持MP4、FLV格式；文件大小不超过100MB）</font></div>
                                    <div class="principle-list" :key="key" v-for="(list,key) in deviceInfoObj.detailDTOList">
                                        <div class="principle-box">
                                            <div class="video" v-if="list.fileFormat === 'video'">
                                                <video :src="list.file" width="100%" height="100%" controls class="file-video"></video>
                                            </div>
                                            <div class="image" v-if="list.fileFormat === 'image'">
                                                <span :style="'background-image:url('+list.file+')'"></span>
                                                <div class="handle"  v-if="list.file!==''">
                                                    <div class="box">
                                                        <a href="javascript:" @click="onImageAmplify(list.title,list.file,list.fileUrl)"><Icon custom="look-big" :size="25"/></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="progress" v-if="list.visible">
                                                <Progress :percent="complete" />
                                            </div>
                                        </div>
                                        <div class="video-number">
                                            <div class="text">
                                                <label>标题：<font>*</font></label>
                                                <Input v-model="list.title" placeholder="输入资料标题" style="width: 410px"></Input>
                                            </div>
                                            <div class="btn">
                                                <Button type="text" :disabled="devicePrincipleLoading" icon="ios-trash-outline" @click="onPrincipleFileDelete(key)">删除</Button>
                                                <Button type="text" :disabled="devicePrincipleLoading" icon="md-refresh" :class="devicePrincipleLoading?'':'blue'" @click="choiceVideo(key)">重新上传</Button>
                                                <Dropdown placement="bottom">
                                                    <a href="javascript:">
                                                        <Icon custom="more"/>
                                                    </a>
                                                    <DropdownMenu slot="list">
                                                        <DropdownItem v-if="key !==0" @click.native="onProgressTopping(key)">
                                                            <a href="javascript:">置顶</a>
                                                        </DropdownItem>
                                                        <DropdownItem v-if="key !==0" @click.native="onProgressUp(key)">
                                                            <a href="javascript:">上移</a>
                                                        </DropdownItem>
                                                        <DropdownItem v-if="key+1 !== deviceInfoObj.detailDTOList.length" @click.native="onProgressDown(key)">
                                                            <a href="javascript:">下移</a>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="principle-btn-body">
                                        <Button type="primary" :disabled="devicePrincipleLoading"  icon="ios-add" @click="choiceVideo(deviceInfoObj.detailDTOList.length)">继续上传</Button>
                                    </div>
                                </div>
                                <input type="file" ref="principleVideo" accept="video/mp4,video/flv,image/jpg,image/jpeg,image/png" class="file" @change="onPrincipleFileChange($event)">
                                <div class="principle-text">
                                    <span class="title">原理简介</span>
                                    <div class="principle-input">
                                        <Input type="textarea" maxlength="800" show-word-limit placeholder="输入设备原理..." v-model="deviceInfoObj.principleContent"></Input>
                                    </div>
                                    <div class="principle-btn">
                                        <Button type="primary" :disabled="devicePrincipleLoading" :loading="devicePrincipleEditLoading" @click="onAddDevicePrincipleCheck">保存</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 4">
                        <div class="tab-part-title">
                            <Icon custom="icon-part"></Icon>
                            {{ deviceInfo.equipmentName }} <font>{{ JSON.stringify(deviceSelectPart) !=='{}'?'/':'' }}  {{ deviceSelectPart.partsName }}</font>
                        </div>
                        <div class="tab-part-content">

                            <!--s: 左侧-->
                            <div class="part-left">
                                <div class="part-left-title">
                                    <span class="text">部件列表（{{ deviceInfo.partsSize }}）</span>
                                    <a href="javascript:" class="add" @click="onEditStructural('')"><Icon type="ios-add-circle-outline" size="18" />  新增</a>
                                </div>
                                <div class="part-left-content">
                                    <div class="list-item">
                                        <div class="item" :key="'item'+key" v-for="(item,key) in deviceInfoArray">
                                            <div class="item-thead" :class="item.id === deviceSelectPart.id?'item-active':''">
                                                <a href="javascript:" class="href">
                                                    <Icon :type="item.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon"  @click="item.visible = !item.visible"/>
                                                    <p @click="onSelectPart(item)">{{ item.partsName }}</p>
                                                </a>
                                                <div class="child-system">
                                                    <Dropdown placement="bottom">
                                                        <a href="javascript:">
                                                            <Icon :custom="item.id === deviceSelectPart.id?'more-blue':'more'"/>
                                                        </a>
                                                        <DropdownMenu slot="list">
                                                            <DropdownItem>
                                                                <a href="javascript:" @click="onEditStructural(item,item.id,deviceInfoArray,'')">编辑</a>
                                                            </DropdownItem>
                                                            <DropdownItem>
                                                                <a href="javascript:" @click="onDelStructural(item.id)">删除</a>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                    <a href="javascript:" class="href" @click="onEditStructural('',item.id)"><Icon type="ios-add-circle-outline" class="icon"/></a>
                                                </div>
                                            </div>
                                            <div class="item-body" v-if="item.visible">
                                                <div class="item-child" :key="'child2'+key" v-for="(children,key) in item.childNode">
                                                    <div class="child-thead" :class="children.id === deviceSelectPart.id?'children-active':''" :style="children.childNode.length === 0?'padding-left:20px':''">
                                                        <a href="javascript:" class="href">
                                                            <Icon v-if="children.childNode.length !== 0"  @click="children.visible = !children.visible" :type="children.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon" />
                                                            <p @click="onSelectPart(children)">{{ children.partsName }}</p>
                                                        </a>
                                                        <div class="child-system">
                                                            <Dropdown placement="bottom">
                                                                <a href="javascript:">
                                                                    <Icon :custom="children.id === deviceSelectPart.id?'more-blue':'more'"/>
                                                                </a>
                                                                <DropdownMenu slot="list">
                                                                    <DropdownItem>
                                                                        <a href="javascript:" @click="onUpgradeDevice(children.id)">升级</a>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <a href="javascript:" @click="onEditStructural(children,children.id,item.childNode,item.id)">编辑</a>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <a href="javascript:" @click="onDelStructural(children.id)">删除</a>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                            <a href="javascript:" class="href" @click="onEditStructural('',children.id)"><Icon type="ios-add-circle-outline" class="icon"/></a>
                                                        </div>
                                                    </div>
                                                    <div class="child-body" v-if="children.visible">
                                                        <ul>
                                                            <li :key="'child3'+key" v-for="(child,key) in children.childNode" :class="child.id === deviceSelectPart.id?'child-active':''" >
                                                                <a href="javascript:" class="href" @click="onSelectPart(child)"><Icon type="ios-copy-outline" class="icon"/>{{ child.partsName }}</a>
                                                                <div class="child-system">
                                                                    <Dropdown placement="bottom">
                                                                        <a href="javascript:">
                                                                            <Icon :custom="child.id === deviceSelectPart.id?'more-blue':'more'"/>
                                                                        </a>
                                                                        <DropdownMenu slot="list">
                                                                            <DropdownItem>
                                                                                <a href="javascript:" @click="onUpgradeDevice(child.id)">升级</a>
                                                                            </DropdownItem>
                                                                            <DropdownItem>
                                                                                <a href="javascript:" @click="onEditStructural(child,child.id,item.childNode,children.id)">编辑</a>
                                                                            </DropdownItem>
                                                                            <DropdownItem>
                                                                                <a href="javascript:" @click="onDelStructural(child.id)">删除</a>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--e: 左侧-->

                            <!--s: 右侧-->
                            <div class="part-right">
                                <div v-if="JSON.stringify(deviceSelectPart) === '{}' || deviceSelectPart.fileUrl === ''" class="part-right-no-image"></div>
                                <div v-if="JSON.stringify(deviceSelectPart) !== '{}' && deviceSelectPart.fileUrl !== ''" class="part-right-content">
                                    <span v-if="onFileFormatCheck(deviceSelectPart.fileUrl)" :style="'background-image:url('+deviceSelectPart.file+')'" class="image"></span>
                                    <video v-if="!onFileFormatCheck(deviceSelectPart.fileUrl) && deviceSelectPart.file" :src="deviceSelectPart.file" width="100%" height="100%" autoplay controls class="file-video"></video>
                                </div>
                                <div class="part-top-bar" v-if="JSON.stringify(deviceSelectPart) !== '{}'">
                                    <div class="bar-body">
                                        <Poptip placement="top" theme="light">
                                            <a href="javascript:" class="info"></a>
                                            <div slot="content"  class="bar-content">
                                                <span class="title"><font></font> 部件型号：</span>
                                                <div class="text">{{ deviceSelectPart.partsModel === ''?'未添加部件型号':deviceSelectPart.partsModel }}</div>
                                                <span class="title"><font></font> 部件说明：</span>
                                                <div class="text">{{ deviceSelectPart.partsBlurb === ''?'未添加简介':deviceSelectPart.partsBlurb }}</div>
                                            </div>
                                        </Poptip>
                                        <a href="javascript:" class="look" @click="onImageAmplify(deviceSelectPart.partsName,deviceSelectPart.file,deviceSelectPart.fileUrl)"></a>
                                    </div>
                                </div>
                            </div>
                            <!--e: 右侧-->
                        </div>
    <!--                    <div class="title">-->
    <!--                        <a href="javascript:" @click="onEditStructural('')">-->
    <!--                            <Icon type="ios-add" class="icon" :size="20"/>-->
    <!--                            新增-->
    <!--                        </a>-->
    <!--                        <Button icon="ios-trash" @click="onStructuralBatchDelete" style="margin-left: 30px">删除</Button>-->
    <!--                    </div>-->
    <!--                    <div class="table">-->
    <!--                        <Table ref="selection" stripe="stripe" :loading="deviceInfoLoading" :height="faultTableHeight" :columns="structuralElementColumns" :data="deviceInfoArray" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel" @on-select="onSelect" @on-select-cancel="onSelectCancel">-->
    <!--                            <template slot-scope="{ index }" slot="序号">-->
    <!--                                {{ index+1 }}-->
    <!--                            </template>-->
    <!--                            <template slot-scope="{ row }" slot="部件图片">-->
    <!--                                <div class="device-image">-->
    <!--                                    <img v-if="onFileFormatCheck(row.fileUrl)" :src="row.file" class="image"/>-->
    <!--                                    <video v-if="!onFileFormatCheck(row.fileUrl) && row.file" width="100%" height="100%" controls class="file-video">-->
    <!--                                        <source :src="row.file" type="video/mp4">-->
    <!--                                        您的浏览器不支持Video标签。-->
    <!--                                    </video>-->
    <!--                                    <div class="image-error" v-if="!row.file">-->
    <!--                                        <div class="box">-->
    <!--                                            <span class="table-image"><Icon type="ios-images" size="25"/><font >未添加图片</font></span>-->
    <!--                                        </div>-->
    <!--                                    </div>-->
    <!--                                    <div class="handle" v-if="row.file">-->
    <!--                                        <div class="box">-->
    <!--                                            <a href="javascript:" @click="onImageAmplify(row.partsName,row.file,row.fileUrl)"><Icon custom="look-big" :size="25"/></a>-->
    <!--                                        </div>-->
    <!--                                    </div>-->
    <!--                                </div>-->
    <!--                            </template>-->
    <!--                            <template slot-scope="{ row }" slot="操作">-->
    <!--                                <a class="btn blue" href="javascript:" @click="onEditStructural(row)"><Icon type="ios-create" :size="22"/></a>-->
    <!--                                <a class="btn" href="javascript:" @click="onDelStructural(row.id)"><Icon type="ios-trash" :size="22"/></a>-->
    <!--                            </template>-->
    <!--                        </Table>-->
    <!--                    </div>-->
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 5">
                        <div class="title">
                            <Button icon="ios-cloud-download-outline" @click="onFailureDownLoad" :loading="failureDownLoadLoading" style="margin-left: 50px">下载模版</Button>
                            <Button icon="ios-redo-outline" @click="onFailureExport" :loading="failureExportLoading">导出</Button>
                            <Button icon="ios-trash" @click="onFailureBatchDelete">删除</Button>
    <!--                        <a href="javascript:" class="title-export">-->
    <!--                            <Icon type="ios-cloud-upload-outline" class="icon" :size="20"/>-->
    <!--                            导出Excel-->
    <!--                        </a>-->
                            <a href="javascript:" @click="onEditFailure('')">
                                <Icon type="ios-add" class="icon" :size="20"/>
                                新增
                            </a>
                            <Button icon="ios-download-outline" @click="exportVisible = true;faultsVisible = false;" style="float:right">导入</Button>
                        </div>
                        <div class="table">
                            <Table ref="selection" :loading="deviceInfoLoading" stripe="stripe" :height="faultTableHeight" :columns="faultDataColumns" :data="deviceInfoArray" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel" @on-select="onSelect" @on-select-cancel="onSelectCancel">
                                <template slot-scope="{ index }" slot="序号">
                                    {{ index+1 }}
                                </template>
                                <template slot-scope="{ row }" slot="故障代码">
                                  {{ row.code && row.code!==''?row.code:'/' }}
                                </template>
                                <template slot-scope="{ row }" slot="操作">
                                    <a class="btn blue" href="javascript:" @click="onEditFailure(row)"><Icon type="ios-create" :size="22"/></a>
                                    <a class="btn" href="javascript:" @click="onDelFailure(row.id)"><Icon type="ios-trash" :size="22"/></a>
                                </template>
                                <template slot-scope="{ row,index }" slot="移动排序">
                                    <a href="javascript:" v-if="(index+1)!==1" @click="onSortableFault(row.id,1)"><Icon type="ios-arrow-round-up" :size="22"/></a>
                                    <a href="javascript:" v-if="(index+1)!==faultsList.length" @click="onSortableFault(row.id,2)"><Icon type="ios-arrow-round-down" :size="22"/></a>
                                    <a href="javascript:" v-if="(index+1)!==1" @click="onSortableFault(row.id,3)">置顶</a>
                                    {{ index === 0 && faultsList.length === 1?'/':'' }}
                                </template>
                            </Table>
                        </div>
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 1">
                        <div class="title">
                            <a href="javascript:">
                                <input type="file" :ref="`dataInputer${1}`" accept="application/pdf" multiple class="file" @change="onFileDataChange($event,1)">
                                <Icon type="ios-add" class="icon" :size="20"/>
                                新增
                            </a>
                            <Button icon="ios-trash" @click="onBatchDelete" style="margin-left: 30px">删除</Button>
                        </div>
                        <div class="table text-btn">
                            <Table ref="selection" :loading="deviceInfoLoading" :height="faultTableHeight" :columns="deviceDataColumns" :data="deviceInfoArray" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel" @on-select="onSelect" @on-select-cancel="onSelectCancel">
                                <template slot-scope="{ index }" slot="序号">
                                    {{ index+1 }}
                                </template>
                                <template slot-scope="{ row }" slot="文件名称">
                                    <a href="javascript:" class="target" @click="onImageAmplify(row.fileName,row.file,row.fileUrl)">{{ row.fileName }}</a>
                                </template>
                                <template slot-scope="{ row }" slot="文件大小">
                                    {{ renderSize(row.fileSize) }}
                                </template>
                                <template slot-scope="{ row }" slot="操作">
                                    <a href="javascript:" class="blue">
                                        重新上传
                                        <input type="file" :ref="`filePath${1}`" accept="application/pdf" class="file" @change="onUpdateFileDataChange($event,row.id,1)">
                                    </a>
                                    <a href="javascript:" @click="onDeleteFileData(row.id)">删除</a>
                                </template>
                            </Table>
                        </div>
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 2">
                        <div class="title">
                            <a href="javascript:">
                                <Icon type="ios-add" class="icon" :size="20"/>
                                新增
                                <input type="file" :ref="`dataInputer${2}`" accept="image/*" multiple="multiple" class="file" @change="onFileDataChange($event,2)">
                            </a>
                            <Button icon="ios-download-outline" @click="choicePdfElectrical" style="float:right">导入PDF</Button>
                            <Button icon="ios-trash" @click="onBatchDelete" style="margin-left: 30px">删除</Button>
                            <input type="file" ref="pdf" accept=".pdf" style="display: none" @change="onPdfFileChange($event,2)">
                        </div>
                        <div class="table text-btn">
                            <Table ref="selection" stripe="stripe" :loading="deviceInfoLoading" :height="faultTableHeight" :columns="deviceDataColumns" :data="deviceInfoArray" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel" @on-select="onSelect" @on-select-cancel="onSelectCancel">
                                <template slot-scope="{ index }" slot="序号">
                                    {{ index+1 }}
                                </template>
                                <template slot-scope="{ row,index }" slot="文件名称">
                                    <a href="javascript:" v-if="!row.visible" class="target" @click="onImageSwitch(row,deviceInfoArray,index)">{{ row.fileName }}</a>
                                    <Input v-model="deviceRename" ref="editor" v-if="row.visible" placeholder="输入文件名称" show-word-limit :maxlength="30" class="device-rename" @on-blur="onBlurRename(row,index)"></Input>
    <!--                                <div ref="editor" v-if="row.visible" class="text-content" @input="getData" tabIndex="0" contenteditable="true">{{ deviceRename }}</div>-->
                                </template>
                                <template slot-scope="{ row }" slot="文件大小">
                                    {{ renderSize(row.fileSize) }}
                                </template>
                                <template slot-scope="{ row,index }" slot="操作">
                                    <a href="javascript:" class="blue" @click="onDeviceRename(index)">重命名</a>
                                    <a href="javascript:" class="blue">
                                        重新上传
                                        <input type="file" :ref="`filePath${1}`" accept="image/*" class="file" @change="onUpdateFileDataChange($event,row.id,2)">
                                    </a>
                                    <a href="javascript:" @click="onDeleteFileData(row.id)">删除</a>
                                </template>
                            </Table>
                        </div>
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 3">
                        <div class="title">
                            <a href="javascript:" @click="onElectricalEdit('')">
                                <Icon type="ios-add" class="icon" :size="20"/>
                                新增
                            </a>
                            <Button icon="ios-trash" @click="onBatchAtlasDelete" style="margin-left: 30px">删除</Button>
                            <Button icon="ios-download-outline" @click="choicePdfElectrical" style="float:right">导入PDF</Button>
                            <input type="file" ref="pdf" accept=".pdf" style="display: none" @change="onPdfFileChange($event,3)">
                        </div>
    <!--                    <div class="card-list" :class="menuVisible ? 'layout-visible': 'card-body-list'">-->
    <!--                        <ul>-->
    <!--                            <li class="card-list-li" :key="key" v-for="(deviceInfo,key) in deviceInfoArray" @click="onElectricalEdit(deviceInfo.id)">-->
    <!--                                <div class="process-card">-->
    <!--                                    <span class="card-image"><img :src="deviceInfo.file"/></span>-->
    <!--                                    <div class="card-content">-->
    <!--                                        <span class="card-li">上传时间：{{ deviceInfo.uploadTime }}</span>-->
    <!--                                        <span class="card-li">修改时间：{{ deviceInfo.updateTime }}</span>-->
    <!--                                        <span class="card-li">文件大小：{{ renderSize(deviceInfo.fileSize) }}</span>-->
    <!--                                    </div>-->
    <!--                                    <div class="edit" @click.stop>-->
    <!--                                        <Dropdown placement="bottom-end">-->
    <!--                                            <a href="javascript:void(0)" class="edit-size">-->
    <!--                                                <Icon type="ios-more"></Icon>-->
    <!--                                            </a>-->
    <!--                                            <DropdownMenu slot="list">-->
    <!--                                                <DropdownItem @click.native="onElectricalRelease(deviceInfo.id,1)" v-if="deviceInfo.status === 0">发布</DropdownItem>-->
    <!--                                                <DropdownItem v-if="deviceInfo.status === 0" @click.native="onUpdateNameStatus(key,deviceInfo)">重命名</DropdownItem>-->
    <!--                                                <DropdownItem v-if="deviceInfo.status === 0 || deviceInfo.status === 1" @click.native="onDownLoadImage(deviceInfo)">下载</DropdownItem>-->
    <!--                                                <DropdownItem v-if="deviceInfo.status === 0" @click.native="onElectricalEdit(deviceInfo.id)">编辑</DropdownItem>-->
    <!--                                                <DropdownItem v-if="deviceInfo.status === 0" @click.native="onElectricalDelete(deviceInfo.id)">删除</DropdownItem>-->
    <!--                                                <DropdownItem v-if="deviceInfo.status === 1" @click.native="onElectricalRelease(deviceInfo.id,0)">撤回</DropdownItem>-->
    <!--                                            </DropdownMenu>-->
    <!--                                        </Dropdown>-->
    <!--                                    </div>-->
    <!--                                    <div class="badge" :class="deviceInfo.status === 0?'red':'green'">{{ deviceInfo.status === 0?'未发布':'已发布' }}</div>-->
    <!--                                </div>-->
    <!--                                <div class="card-name-box">-->
    <!--                                    <p class="name" v-if="!deviceInfo.nameStatus">{{ deviceInfo.fileName }}</p>-->
    <!--                                    <Input ref="electricalName" v-model="deviceInfo.fileName" maxlength="20" v-if="deviceInfo.nameStatus" @on-blur="onUpdateName(deviceInfo.id,key)" @on-enter="$event.target.blur()" placeholder="请输入名称" class="name" />-->
    <!--                                </div>-->
    <!--                            </li>-->
    <!--                        </ul>-->
    <!--                        <Spin fix v-if="deviceInfoLoading"></Spin>-->
    <!--                    </div>-->
                        <div class="table text-btn">
                            <Table ref="selection" stripe="stripe" :loading="deviceInfoLoading" :height="faultTableHeight" :columns="deviceDataColumns" :data="deviceInfoArray" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel" @on-select="onSelect" @on-select-cancel="onSelectCancel">
                                <template slot-scope="{ index }" slot="序号">
                                    {{ index+1 }}
                                </template>
                                <template slot-scope="{ row,index }" slot="文件名称">
                                    <a href="javascript:" class="target" v-if="!row.visible" @click="onElectricalEdit(row.id)">{{ row.fileName }}</a>
                                    <Input v-model="deviceRename" ref="editor" v-if="row.visible" placeholder="输入文件名称" show-word-limit :maxlength="30" class="device-rename" @on-blur="onUpdateName(row.id,index)"></Input>
                                </template>
                                <template slot-scope="{ row }" slot="文件大小">
                                    {{ renderSize(row.fileSize) }}
                                </template>
                                <template slot-scope="{ row,index }" slot="操作">
                                    <a href="javascript:" class="blue" @click="onDeviceRename(index)">重命名</a>
                                    <a href="javascript:" class="blue" @click="onDownLoadImage(row)">下载</a>
                                </template>
                            </Table>
                        </div>
                    </div>
                    <div class="tab-box" v-if="deviceTabsNumber === 0">
                        <div class="title">
                            <a href="javascript:">
                                <Icon type="ios-add" class="icon" :size="20"/>
                                新增
                                <input type="file" :ref="`dataInputer${0}`" multiple="multiple" class="file" @change="onFileDataChange($event,0)">
                            </a>
                            <Button icon="ios-trash" @click="onBatchDelete" style="margin-left: 30px">删除</Button>
                        </div>
                        <div class="table text-btn">
                            <Table ref="selection" stripe="stripe" :loading="deviceInfoLoading" :height="faultTableHeight" :columns="deviceDataColumns" :data="deviceInfoArray" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel" @on-select="onSelect" @on-select-cancel="onSelectCancel">
                                <template slot-scope="{ index }" slot="序号">
                                    {{ index+1 }}
                                </template>
                                <template slot-scope="{ row }" slot="文件名称">
                                    <a href="javascript:" class="target" @click="onImageAmplify(row.fileName,row.file,row.fileUrl)">{{ row.fileName }}</a>
                                </template>
                                <template slot-scope="{ row }" slot="文件大小">
                                    {{ renderSize(row.fileSize) }}
                                </template>
                                <template slot-scope="{ row }" slot="操作">
                                    <a href="javascript:" class="blue">
                                        重新上传
                                        <input type="file"  :ref="`filePath${4}`" class="file" @change="onUpdateFileDataChange($event,row.id,4)">
                                    </a>
                                    <a href="javascript:" @click="onDeleteFileData(row.id)">删除</a>
                                </template>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

            <!--s: Modal 管理生产设备型号-->
            <Modal
                v-model="deviceModelVisible"
                title="管理设备型号"
                width="580"
                :loading="submitLoading"
                @on-visible-change="onModelVisibleChange"
                footer-hide>
                <div class="device-modal-info-box">

                    <div class="device-modal-title">
                        <div class="modal-search">
                            <Icon type="ios-search" class="search" size="20"></Icon>
                            <Input v-model="deviceModelValue" placeholder="输入设备型号" style="width: 300px"></Input>
                        </div>
                        <div class="modal-btn">
                            <Button @click="onDeviceModalResetting">重置</Button>
                            <Button type="primary" @click="onDeviceModalSearch">搜索</Button>
                        </div>
                    </div>

                    <div class="device-modal-list" id="deviceList">
                        <ul>
                            <li class="modal-hover" :key="key" v-for="(deviceModel,key) in deviceModelSelect">
                                <div class="modal-text" v-if="deviceModel.visible">
                                    <div class="text">{{ deviceModel.name }}</div>
                                    <div class="btn">
                                        <a href="javascript:" @click="onDeviceModalEdit(key)"><Icon type="md-create" /></a>
                                        <a href="javascript:" @click="onDeviceModalDel(key)"><Icon type="ios-close-circle-outline" /></a>
                                    </div>
                                </div>
                                <div class="modal-add-li" v-if="!deviceModel.visible">
                                    <div class="modal-add">
                                        <Input v-model="deviceModelInfo" placeholder="输入设备型号" show-word-limit maxlength="25" style="width: 402px"></Input>
                                    </div>
                                    <div class="btn">
                                        <a href="javascript:" @click="onDeviceModelEditCheck(key)"><Icon type="ios-checkmark-circle-outline" /></a>
                                        <a href="javascript:" @click="onDeviceModalClose(key)"><Icon type="ios-close-circle-outline" /></a>
                                    </div>
                                </div>
                            </li>
                            <li class="modal-add-li" v-if="deviceAddVisible">
                                <div class="modal-add">
                                    <Input v-model="deviceParams.deviceName" placeholder="输入设备型号" show-word-limit maxlength="25" style="width: 402px"></Input>
                                </div>
                                <div class="btn">
                                    <a href="javascript:" @click="onDeviceModelCheck"><Icon type="ios-checkmark-circle-outline" /></a>
                                    <a href="javascript:" @click="deviceAddVisible = false;deviceParams.deviceName = ''"><Icon type="ios-close-circle-outline" /></a>
                                </div>
                            </li>
                        </ul>
                        <div class="no-data" v-if="deviceModelSelect.length === 0">未找到您要搜索的数据</div>
                    </div>

                    <div class="device-modal-btn">
                        <Button type="primary" icon="ios-add" :disabled="deviceBtnVisible" ghost @click="onDeviceAddModel">新增</Button>
                    </div>

                </div>
    <!--            <div class="device-modal">-->
    <!--                <span class="label">设备型号：</span>-->
    <!--                <Input v-model="deviceParams.deviceName" type="textarea" maxlength="25" show-word-limit placeholder="请输入" style="width: 300px" />-->
    <!--            </div>-->
    <!--            <div class="device-footer">-->
    <!--                <Button @click="deviceModelVisible = false">取 消</Button>-->
    <!--                <Button type="primary" :loading="submitLoading" @click="onDeviceModelCheck">确 定</Button>-->
    <!--            </div>-->
            </Modal>
            <!--e: Modal 管理生产设备型号-->

            <!--s: Modal 新增生产厂家-->
            <Modal
                v-model="manufacturerVisible"
                title="管理生产厂家"
                width="580"
                :loading="submitLoading"
                @on-visible-change="onFactureVisibleChange"
                footer-hide>
    <!--            <div class="device-modal">-->
    <!--                <span class="label">生产厂家：</span>-->
    <!--                <Input v-model="manufacturerParams.manufacturerName" type="textarea" maxlength="25" show-word-limit placeholder="请输入" style="width: 300px" />-->
    <!--            </div>-->
    <!--            <div class="device-footer">-->
    <!--                <Button @click="manufacturerVisible = false;manufacturerParams.manufacturerName = ''">取 消</Button>-->
    <!--                <Button type="primary" :loading="submitLoading" @click="onManufacturerCheck">确 定</Button>-->
    <!--            </div>-->
                <div class="device-modal-info-box">

                    <div class="device-modal-title">
                        <div class="modal-search">
                            <Icon type="ios-search" class="search" size="20"></Icon>
                            <Input v-model="deviceFactureValue" placeholder="输入生产厂家" style="width: 300px"></Input>
                        </div>
                        <div class="modal-btn">
                            <Button @click="onDeviceFactureResetting">重置</Button>
                            <Button type="primary" @click="onDeviceFactureSearch">搜索</Button>
                        </div>
                    </div>

                    <div class="device-modal-list" id="deviceFactureList">
                        <ul>
                            <li class="modal-hover" :key="key" v-for="(deviceFacture,key) in deviceFactureSelect">
                                <div class="modal-text" v-if="deviceFacture.visible">
                                    <div class="text">{{ deviceFacture.name }}</div>
                                    <div class="btn">
                                        <a href="javascript:" @click="onDeviceFactureEdit(key)"><Icon type="md-create" /></a>
                                        <a href="javascript:" @click="onDeviceFactureDel(key)"><Icon type="ios-close-circle-outline" /></a>
                                    </div>
                                </div>
                                <div class="modal-add-li" v-if="!deviceFacture.visible">
                                    <div class="modal-add">
                                        <Input v-model="deviceFactureInfo" placeholder="输入生产厂家" show-word-limit maxlength="25" style="width: 402px"></Input>
                                    </div>
                                    <div class="btn">
                                        <a href="javascript:" @click="onDeviceFactureEditCheck(key)"><Icon type="ios-checkmark-circle-outline" /></a>
                                        <a href="javascript:" @click="onDeviceFactureClose(key)"><Icon type="ios-close-circle-outline" /></a>
                                    </div>
                                </div>
                            </li>
                            <li class="modal-add-li" v-if="deviceAddVisible">
                                <div class="modal-add">
                                    <Input v-model="manufacturerParams.manufacturerName" placeholder="输入生产厂家" show-word-limit maxlength="25" style="width: 402px"></Input>
                                </div>
                                <div class="btn">
                                    <a href="javascript:" @click="onDeviceFactureCheck"><Icon type="ios-checkmark-circle-outline" /></a>
                                    <a href="javascript:" @click="deviceAddVisible = false;manufacturerParams.manufacturerName = ''"><Icon type="ios-close-circle-outline" /></a>
                                </div>
                            </li>
                        </ul>
                        <div class="no-data" v-if="deviceFactureSelect.length === 0">未找到您要搜索的数据</div>
                    </div>

                    <div class="device-modal-btn">
                        <Button type="primary" icon="ios-add" :disabled="deviceBtnVisible" ghost @click="onDeviceAddFacture">新增</Button>
                    </div>

                </div>

            </Modal>
            <!--e: Modal 新增生产厂家-->
            <!--s: Modal 新增故障-->
            <Modal
                v-model="addFaultVisible"
                :title="faultStatus?'新增故障':'编辑故障'"
                footer-hide>
                <div class="device-modal">
                    <span class="label">故障描述：<font>*</font></span>
                    <Input v-model="faultParams.describe" type="textarea" maxlength="30"  show-word-limit  placeholder="请输入" style="width: 300px" />
                </div>
                <div class="device-modal">
                    <span class="label">故障代码：</span>
                    <Input v-model="faultParams.code" type="textarea" maxlength="30"  show-word-limit  placeholder="请输入" style="width: 300px" />
                </div>
                <div class="device-footer">
                    <Button @click="addFaultVisible = false;">取 消</Button>
                    <Button type="primary" :loading="submitLoading" @click="onAddFailureCheck">确 定</Button>
                </div>
            </Modal>
            <!--e: Modal 新增故障-->

            <!--s: Medal 编辑结构部件-->
            <Modal
                v-model="structuralVisible"
                :title="structuralStatus?'新增结构部件':'编辑结构部件'"
                :closable="!speedSingleVisible"
                :mask-closable="!speedSingleVisible"
                footer-hide>
                <div class="device-modal">
                    <span class="label">部件名称：<font>*</font></span>
                    <Input v-model="structuralParams.partsName" maxlength="20"  show-word-limit  placeholder="请输入" style="width: 400px" />
                </div>
                <div class="device-modal">
                    <span class="label">部件型号：</span>
                    <Input v-model="structuralParams.partsModel" maxlength="20"  show-word-limit  placeholder="请输入" style="width: 400px" />
                </div>
                <div class="device-modal">
                    <span class="label">部件说明：</span>
                    <Input type="textarea" v-model="structuralParams.partsBlurb" maxlength="30"  show-word-limit  placeholder="请输入" style="width: 400px" />
                </div>
                <div class="device-modal" v-if="!structuralStatus">
                    <span class="label">转移：</span>
                    <Select v-model="devicePartType" clearable  style="width:400px">
                        <Option v-for="(type,key) in devicePartTypeList" v-show="structuralParams.id!==type.id" :value="type.id" :key="key">{{ type.partsName }}</Option>
                    </Select>
                </div>
                <div class="device-modal structural-top">
                    <span class="label">上传部件：</span>
                    <div class="device-structural-image">
                        <div class="box">
                            <div class="add-icon" v-if="!speedSingleVisible">
                                <a href="javascript:">
                                    <Icon type="ios-add" :size="30"/>
                                </a>
                            </div>
                            <div class="add-text" v-if="!speedSingleVisible">添加图片或mp4视频<br/>文件大小不超过20M</div>
                            <div class="progress" v-if="speedSingleVisible">
                                <Progress :percent="complete" />
                            </div>
                            <input type="file" v-if="!speedSingleVisible" accept="image/*,video/mp4" ref="pathClear" class="file" @change="onStructuralFileChange($event)">
                            <div class="file-image" v-if="structuralParams.fileUrl!==''" :style="!onFileFormatCheck(structuralParams.resourceName)?'background:#000':''">
                                <img v-if="onFileFormatCheck(structuralParams.resourceName)" :src="structuralParams.fileUrl" class="image">
                                <video v-if="!onFileFormatCheck(structuralParams.resourceName)" width="100%" height="100%" controls class="file-video">
                                    <source :src="structuralParams.fileUrl" type="video/mp4">
                                    您的浏览器不支持Video标签。
                                </video>
                                <div class="handle">
                                    <div class="box">
                                        <a href="javascript:" @click="onImageAmplify(structuralParams.partsName,structuralParams.fileUrl,structuralParams.resourceName)"><Icon custom="look-big" :size="25"/></a>
                                        <a href="javascript:" @click="onClearImage(4)"><Icon type="ios-trash-outline" :size="25"/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="device-footer">
                    <Button :disabled="speedSingleVisible" @click="structuralVisible = false;">取 消</Button>
                    <Button type="primary" :disabled="speedSingleVisible" :loading="submitLoading" @click="onAddStructuralCheck">确 定</Button>
                </div>
            </Modal>
            <!--e: Medal 编辑结构部件-->

            <!--s: Modal 编辑可能原因-->
            <Modal
                v-model="reasonVisible"
                :title="reasonStatus?'新增解决方案':'修改解决方案'"
                width="600"
                :closable="!reasonLoading"
                :mask-closable="!reasonLoading"
                footer-hide>
                <Spin v-if="reasonLoading" fix>上传中...</Spin>
                <div class="device-modal-info">
                    <span class="label">可能原因：</span>
                    <Input v-model="reasonArray.reason" maxlength="20" show-word-limit placeholder="请输入" style='width:375px' class="textarea-in"  />
                </div>
                <div class="device-modal-info">
                    <span class="label">处理方法：</span>
                    <ul class="modal-ul">
                        <li :key="key" v-for="(reason,key) in reasonArray.solutionList">
                            <div class="image">
                                <div class="box" v-if="reason.solutionResourcesFileUrl === ''" >
                                    <div class="center">
                                        <input type="file" accept="image/*" @change="onReasonUpLoad($event,key)" class="upload-input">
                                        <Icon  custom="upload-icon" style="color: #515a6e"></Icon>
                                        <p>上传图片</p>
                                    </div>
                                </div>
                                <div class="upload-image-box" v-if="reason.solutionResourcesFileUrl !== ''">
                                    <span :style="'background-image:url('+reason.picUrl+')'" class="image"></span>
                                    <div class="handle">
                                        <div class="box">
                                            <a href="javascript:" @click="onImageAmplify(reasonArray.reason,reason.picUrl,reason.solutionResourcesFileUrl)"><Icon custom="look-big" :size="20"/></a>
                                            <a href="javascript:" @click="onClearReasonImage(key)"><Icon type="ios-trash-outline" :size="20"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Input  v-model="reason.solution" maxlength="100" show-word-limit type="textarea" placeholder="请输入" class="textarea" />
                            <div class="remove" v-if="reasonArray.solutionList.length>1">
                                <a href="javascript:" @click="onRemoveProcessing(key)"><Icon type="ios-remove-circle" size="25" /></a>
                            </div>
                            <div class="sort">
                                <a href="javascript:" v-if="(key+1)!==1" @click="onReasonSort(reasonArray.solutionList,key,'up')"><span><Icon type="ios-arrow-round-up" :size="25"/></span></a>
                                <a href="javascript:" v-if="(key+1)!==reasonArray.solutionList.length"  @click="onReasonSort(reasonArray.solutionList,key,'down')"><span><Icon type="ios-arrow-round-down" :size="25"/></span></a>
                            </div>
                        </li>
                        <li>
                            <div class="tab-btn-body">
                                <div class="box" @click="onAddProcessing">
                                    <a href="javascript:">
                                        <Icon type="ios-add" class="icon" :size="16"/>
                                        新增
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="tab-btn-footer">
                                <Button type="primary" :loading="submitLoading" @click="onAddReasonCheck">保 存</Button>
                                <Button @click="reasonVisible = false;">取 消</Button>
                            </div>
                        </li>
                    </ul>
                </div>
            </Modal>
            <!--e: Modal 编辑可能原因-->

            <!--s: Modal 查看可能原因-->
            <Modal
                v-model="reasonLookVisible"
                title="解决方案"
                width="600"
                footer-hide>
                <div class="device-modal-info">
                    <span class="label">可能原因：</span>
                    <div class="text-label">{{ reasonArray.reason }}</div>
                </div>
                <div class="device-modal-info">
                    <span class="label">处理方法：</span>
                    <ul class="modal-ul">
                        <li :key="key" v-for="(reason,key) in reasonArray.solutionList">
                            <div class="image-error" v-if="reason.solutionResourcesFileUrl === ''" >
                                <div class="box">
                                    <span class="table-image"><Icon type="ios-images" size="20"/><font >未添加图片</font></span>
                                </div>
                            </div>
                            <div class="image" v-if="reason.solutionResourcesFileUrl !== ''">
                                <div class="upload-image-box" >
                                    <span :style="'background-image:url('+reason.picUrl+')'" class="image"></span>
                                    <div class="handle">
                                        <div class="box">
                                            <a href="javascript:" @click="onImageAmplify(reasonArray.reason,reason.picUrl,reason.solutionResourcesFileUrl)"><Icon custom="look-big" :size="25"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-info">{{ reason.solution }}</div>
                        </li>
                    </ul>
                </div>
                <a href="javascript:" class="modal-edit" @click="onReasonEdit"><Icon type="ios-create" size="25" />编辑</a>
            </Modal>
            <!--e: Modal 查看可能原因-->

            <!--s: Modal 查看大图-->
            <ViewImage v-model="viewImageVisible" :imageName="imageName" :imageUrl="imageSrc"/>
            <!--e: Modal 查看大图-->
            <!--s: Modal 查看大图图册-->
            <div class="device-modal-list-box" v-if="imageBigLookVisible">
                <div class="modal-title"><span>{{ imageObj.solution }}</span></div>
                <a class="modal-clear" @click="imageBigLookVisible = false;imageRatio = 100;imageBigNumber = 0"><Icon type="md-add" size="30" /></a>
                <div class="modal-image-box" @mousewheel="onMousewheel">
                    <a href="javascript:" class="carousel-btn carousel-l" @click="onImageBigLeft"><Icon type="ios-arrow-back" size="30"/></a>
                    <a href="javascript:" class="carousel-btn carousel-r" @click="onImageBigRight"><Icon type="ios-arrow-forward" size="30"/></a>
                    <Spin fix v-if="imageLoading">
                        <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                        <div>加载中...</div>
                    </Spin>
                    <div class="image-box-mouse" @mousedown="onMouseDown"
                         @mouseup="onMouseUp"
                         @mouseout="onMouseUp"
                         @mousemove="onMouseMove"
                         :style="'width:'+(imageWidth*(imageRatio/100))+'px;height:'+(imageHeight*(imageRatio/100))+'px;left:'+imageLeft+'px;top:'+imageTop+'px'">
                      <img :src="imageObj.picUrl"/>
                    </div>
                </div>
                <div class="modal-control-box">
                  <Tooltip content="放大" placement="top"><a href="javascript:" @click="onImageEnlarge"><i class="enlarge"></i></a></Tooltip>
                  <Tooltip content="缩小" placement="top"><a href="javascript:" @click="onImageNarrow"><i class="narrow"></i><span class="b-before"></span></a></Tooltip>
                  <Tooltip content="图片还原" placement="top"><a href="javascript:" @click="onReduction">{{ imageRatio }}%<span class="b-before"></span></a></Tooltip>
                </div>
                <div class="modal-list">
                    <div class="list-btn"><a href="javascript:" v-if="imageBigNumber!==0" @click="onImageLeft"><Icon type="ios-arrow-back" size="30"/></a></div>
                    <div class="list-box" id="carouselId">
                        <div class="carousel" :style="'transform: translate3d(-'+imageBigNumber*106+'px, 0px, 0px); width:'+106*imageBigArray.length+'px;transition: transform 500ms ease 0s;'">
                            <a href="javascript:" :key="key" @click="onImageSwitch(image,imageBigArray,key)" :class="imageBigActive === key?'active':''" v-for="(image,key) in imageBigArray">
                                <span class="table-image" :style="'background-image:url('+image.file+')'"></span>
                            </a>
                        </div>
                    </div>
                    <div class="list-btn"><a href="javascript:" v-if="imageBigNumber*106<imageBigArray.length*106-offsetWidth" @click="onImageRight"><Icon type="ios-arrow-forward" size="30"/></a></div>
                </div>
            </div>
            <!--e: Modal 查看大图可能原因-->

            <!--s: 导入故障-->
            <Modal
                title="导入故障"
                v-model="exportVisible"
                width="600"
                footer-hide>
                <div class="device-export">
                    <div class="box" v-if="!faultsVisible">
                        <div class="print" v-if="faultsList.length !== 0 && !faultsVisible">当前故障分析已有内容，<br/>为避免重复录入，需清空内容才可导入。</div>
                        <div class="export" v-if="faultsList.length === 0 && !faultsVisible">
                            <div class="upload">
                                <div class="box-a">
                                    <input type="file" @change="onSignUpLoad($event)"  ref="uploadFault" class="upload-input">
                                    <Icon type="ios-cloud-upload" size="52" style="color: #515a6e"></Icon>
                                    <p><a href="javascript:"  @click="choiceImg">点击上传</a></p>
                                </div>
                            </div>
                            <div class="info"><Icon custom="info-icon"></Icon>只能上传固定格式的Excel文件，且不超过8M</div>
                        </div>
                    </div>
                    <div class="box-progress" v-if="faultsVisible">
                        <div class="progress-info">
                            <div class="info-li">
                                <span class="name">{{ faultsSpeed.name }}</span>
                                <span class="size">{{ (faultsSpeed.size / 1024 / 1024).toFixed(2) }}M</span>
                            </div>
                        </div>
                        <div class="progress-box">
                            <div class="progress-outer">
                                <div class="progress-inner">
                                    <div class="progress-bg" :class="complete<100?'before':''" :style="'width: '+complete+'%; height: 10px;'"></div>
                                </div>
                                <div class="progress-text">{{ complete }}%</div>
                            </div>
                        </div>
                        <div class="device-footer" v-if="!faultsCancel">
                            <Button type="primary" v-if="complete === 100" @click="exportVisible = false ;complete = 0">关闭</Button>
                        </div>
                        <div class="device-footer" v-if="faultsCancel">
                            <Button @click="exportVisible = false ;complete = 0;faultsCancel = false;faultsMsg = ''">取消</Button>
                        </div>
                        <div class="device-footer-print" v-if="faultsCancel"><Icon custom="info-icon"></Icon><p v-html="faultsMsg"></p></div>
                    </div>
                </div>
            </Modal>
            <!--e: 导入故障-->

            <!--s: 上传进度-->
            <Modal
                title="上传进度"
                v-model="speedVisible"
                footer-hide
                :mask-closable="false"
                :closable="false">
                <div class="progress-info">
                    <div class="info-li" :key="key" v-for="(speed,key) in speedList">
                        <span class="name">{{ speed.name }}</span>
                        <span class="size">{{ (speed.size / 1024 / 1024).toFixed(2) }}M</span>
                    </div>
                </div>
                <div class="progress-box">
                    <div class="progress-outer">
                        <div class="progress-inner">
                            <div class="progress-bg" :class="completeAsync<100?'before':''" :style="'width: '+completeAsync+'%; height: 10px;'"></div>
                        </div>
                        <div class="progress-text">{{ completeAsync }}%</div>
                    </div>
                </div>
                <div class="device-footer">
                    <Button type="primary" v-if="completeAsync === 100" @click="speedVisible = false;completeAsync = 0">关闭</Button>
                </div>
            </Modal>
            <!--e: 上传进度-->

            <!--s: 导入pdf -->
            <Modal
                title="导入并转换"
                v-model="pdfModalVisible"
                width="600"
                footer-hide
                class-name="pdf-modal"
                :mask-closable="false"
                >
                <div class="pdf-body">
                    <div class="pdf-title">PDF转PNG</div>
                    <div class="pdf-content" v-if="!pdfAddVisible">
                        <div class="pdf-con">
                            <span class="name"><Icon custom="icon-pdf"></Icon>{{ pdfName }}</span>
                            <span class="page" v-if="!pdfUploadStatus">共{{ pdfParams.filePageCount }}页</span>
                            <span class="print">{{ !pdfUploadStatus?'解析完成':'解析中' }}</span>
                        </div>
                        <div class="pdf-plan">
                            <Progress :percent="completePdf" />
                        </div>
                        <div class="pdf-btn">
                            <ul>
                                <li><Button type="text" :disabled="pdfUploadStatus" @click="pdfAddVisible = true;pdfUploadStatus = true" icon="ios-trash">删除</Button></li>
                                <li><Button type="text" @click="choicePdfElectrical" :disabled="pdfUploadStatus" :style="pdfUploadStatus?'':'color: #2d8cf0'" :custom-icon="pdfUploadStatus?'recall-grey':'recall-blue'">重新上传</Button></li>
                            </ul>
                        </div>
                    </div>
                    <div class="pdf-add" v-if="pdfAddVisible">
                        <div class="content">
                            <a href="javascript:" @click="choicePdfElectrical"><Icon type="ios-add" class="icon" :size="18"/></a>
                            <p class="text">上传PDF文件，最大支持30M文件</p>
                        </div>
                    </div>
                </div>
                <div class="pdf-page-number">
                    <div class="number">{{ pdfUploadStatus?'':'总页数:'+pdfParams.filePageCount+'页&nbsp;&nbsp;&nbsp;&nbsp;' }}页码转换范围：<InputNumber :min="1" :max="pdfParams.filePageCount" v-model="pdfPageStar" :disabled="pdfUploadStatus" style="width:60px" /> <font>-</font> <InputNumber :min="1" :max="pdfParams.filePageCount" :disabled="pdfUploadStatus" v-model="pdfPageEnd" style="width:60px"/></div>
                    <Button type="primary" :disabled="pdfUploadStatus" style="float:right;padding:0 35px" @click="onConvert">转换</Button>
                </div>
            </Modal>
            <!--e: 导入pdf -->
        </div>
    </div>
</template>
<script>
import DeviceInfo from './info'
export default DeviceInfo
</script>
<style lang="less">
@import "info";
</style>
